import React, {Component} from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Container from '../Container/Container';

class Base extends Component {
    render() {
        return (
            <div className="min-h-screen md:flex">
                <Sidebar/>
                <Container/>
            </div>
        );
    }
}
export default Base;
