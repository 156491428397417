import {Component} from 'react';
import logo from './../../Assets/logo.png'
import SidebarNavigation from "./Navigation/SidebarNavigation";

class Sidebar extends Component {
    render() {
        return (
            <div className="w-72 bg-blue-200 p-10">
                <div className="flex items-center">
                    <div><img className="w-16" src={logo} alt="Logo"/></div>
                    <div className="pl-5 text-3xl font-bold">Gyxi</div>
                </div>
                <nav className="my-10 text-xl">
                    <SidebarNavigation />
                </nav>
            </div>
        );
    }
}
export default Sidebar;
