import React, {Component} from 'react';
import Base from './Components/Base/Base';

class App extends Component {
    render() {
        return (
            <Base/>
        );
    }
}

export default App;
