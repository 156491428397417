import {Component} from 'react';

class Container extends Component {
    render() {
        return (
            <div>(content)</div>
        );
    }
}
export default Container;
