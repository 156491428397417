import {Component} from "react";
import DatabaseModel from "../../../Models/DatabaseModel";
import {databaseService} from "../../../Services/services";

class SidebarNavigation extends Component<any, IState> {

    constructor(props: any) {
        super(props);
        this.state = {databases: []}
    }

    async componentDidMount() {
        const databases = await databaseService.getAll();
        this.setState({databases: databases});
    }

    render() {
        return (
        <div>
            <a href="/#" className="block">Databases</a>
            <div className="mx-5">
                {this.state.databases.map((database, index) =>
                    <a href="/#" className="block" key={index}>{database.DatabaseId}</a>
                )}
                <a href="/#" className="block italic">Create Database</a>
            </div>
            <a href="/#" className="block">Profile</a>
            <a href="/#" className="block">Security</a>
            <a href="/#" className="block">Log Off</a>
        </div>
        );
    }
}
export default SidebarNavigation;

interface IState {
    databases: DatabaseModel[]
}
