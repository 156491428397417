import IDatabaseService from '../Interfaces/IDatabaseService';
import DatabaseModel from '../../Models/DatabaseModel';

class DatabaseMockService implements IDatabaseService{
    async getAll(): Promise<DatabaseModel[]> {
        return [
            {DatabaseId: 'Database Name 1', Key: 'key-1'},
            {DatabaseId: 'Database Name 2', Key: 'key-2'},
        ];
    }
}
export default DatabaseMockService;
